import CaityJimmy from '../images/caity-Jimmy1.webp'


function About() {
  return (
    <div className="about-page">
      <div className='about-content-all' >
        <h4>ABOUT ME</h4>
        <p>My name is Caity and ever since I was little I’ve had an obsession with creating that has translated through to all things craft and design.  I like to spend my free time learning new creative skills, reading, adventuring with my dog Jimmy and seeing live music.
        </p>
        <p>I love a challenge, and have found that my appreciation of creating has steered me towards the digital world of software engineering. I’d love to be in an environment with fun and inventive folk, learning and teaching new skills.
        </p>
      </div>
      <div className='celeb-image'>
        <img src={CaityJimmy} alt="" />
      </div>
    </div>
  )
}

export default About