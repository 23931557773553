
// CSS
import './App.css';
import '../src/components/css/Cloud.css'
import '../src/components/css/Menu.css'
import 'react-tooltip/dist/react-tooltip.css'

// components
import About from './components/About'
import Projects from './components/Projects'
import Resume from './components/Resume'
import Contact from './components/Contact'
import Skills from './components/Skills'
import ScrollToTop from './components/ScrollToTop'

// images
import ContactCloud from './images/contact-cloud.webp'
import AboutCloud from './images/about-cloud.webp'
import ProjectsCloud from './images/projects-cloud.webp'
import ResumeCloud from './images/resume-cloud.webp'
import Cloud1 from './images/cloud1.webp'
import Cloud2 from './images/cloud2.webp'
import Cloud3 from './images/cloud3.webp'
import Cloud4 from './images/cloud4.webp'
import joke from './images/joke.webp'
import jokeAnswer from './images/joke1.webp'

// fonts
import './GentySans-Regular.ttf';


function App() {

  function tellJoke() {
    if (document.querySelector('.jokeq').hidden === false) {
      document.querySelector('.jokeq').hidden = true
      document.querySelector('.jokea').hidden = false
    } else if (document.querySelector('.jokeq').hidden === true) {
      document.querySelector('.jokeq').hidden = false
      document.querySelector('.jokea').hidden = true
    }
  }


  return (
    <div className="App">
      <div className='phone-disclaimer' hidden={true}>
        <p>This site looks better on a bigger screen</p>
      </div>
      <div><ScrollToTop /></div>
      <div className='circle-wrap'>
        <div className='logo'>
          <h1>CAITY LANE</h1>
        </div>
        <div className='title-img'>
          <h2>Software Engineer & Designer</h2>
        </div>
      </div>

      <div className='projects-div'>
        <a href="#Projects-Page2"> <img src={ProjectsCloud} alt="" /> </a>
      </div>

      <div className='clouds-total'>
        <div className='clouds-2'>
          <div className='about-div'>
            <a href="#About-Page2"> <img src={AboutCloud} alt="" /></a>
          </div>
          <div className='resume-div'>
            <a href="#Resume-Page2"> <img src={ResumeCloud} alt="" /></a>
          </div>
          <div className='contact-div'>
            <a href="#Contact-Page2"> <img src={ContactCloud} alt="" /></a>
          </div>
        </div>

        <div className='Clouds-3'>
          <div className='Cloud1'>
            <img src={Cloud1} alt="" />
          </div>
          <div className='Cloud2'>
            <img src={Cloud2} alt="" />
          </div>
          <div className='Cloud3'>
            <img src={Cloud3} alt="" />
          </div>
          <div className='Cloud6'>
            <img src={Cloud1} alt="" />
          </div>
          <div className='Cloud4'>
            <img src={Cloud4} alt="" />
          </div>
          <div className='Cloud5'>
            <img src={Cloud4} alt="" />
          </div>

          <div className='jokeCloud' onClick={tellJoke}>
            <img className="jokeq" src={joke} alt="" hidden={false} />
            <img className="jokea" src={jokeAnswer} alt="" hidden={true} />
          </div>
        </div>

      </div>

      <section className='layout-div'>

        <section className="about-Page" id="About-Page2">
          <About />
        </section>

        <section className="Projects-Page" id="Projects-Page2">
          <Projects />
        </section>

        <section className="Skills-Page">
          <Skills />
        </section>

        <section className="Resume-Page" id="Resume-Page2">
          <Resume />
        </section>

        <section className="Contact-Page" id="Contact-Page2">
          <Contact />
        </section>

        <div className='Cloud7'>
          <img src={Cloud2} alt="" />
        </div>
      </section>
    </div >

  );
}

export default App;
