import React from "react";
import FaeVideo from '../videos/fae-theGame-screenrecording1.gif'
import IChewsYou from '../videos/IChewsYou1.gif'
import TicTacToe from '../videos/ticTacToe1.gif'
import { FaGithubSquare, FaLink } from 'react-icons/fa'


function Projects() {
  return (
    <div className="projects-page">
      <h4>Here's some stuff I've made...</h4>
      <div className="project1">
        <img src={FaeVideo} alt="" />
        <div className="project1-content">
          <h3>THE FAE FOREST - trivia game</h3>
          <p>My final project at General Assembly - I wanted to create a spooky, fantastical trivia game with a minimal colour palatte. A user moves through the game and needs to collect crystals to throw at the evil, and insulting Fae. Questions are pulled from a trivia API and a user receives a crystal for each correct answer.</p>
          <p>This project was built in React with Javascript/JSX, SCSS and HTML.</p>
          <div className="project-links">
            <a href="https://github.com/clane62/fae-game">
              <FaGithubSquare />
            </a>
            <a href="https://fae-game.surge.sh/">
              < FaLink />
            </a>
          </div>
        </div>
      </div>
      <div className="project2">
        <img src={TicTacToe} alt="" />
        <div className="project1-content">
          <h3>TIC TAC TOE - two player game</h3>
          <p>A classic, two player game of Tic Tac Toe. Users can enter their details and play multiple games against one another with the score accumulating. Player one is assigned the colour purple and Player two is assigned pink. As they choose a square, the colour is allocated to the square marking their move with either player's wins or draws being announced after each game.
          </p>
          <p>This project was built in with Javascript, CSS and HTML.</p>
          <div className="project-links">
            <a href="https://github.com/clane62/tic-tac-toe">
              <FaGithubSquare />
            </a>
            <a href="https://clane62.github.io/tic-tac-toe/">
              < FaLink />

            </a>
          </div>
        </div>
      </div>
      <div className="project3">
        <img src={IChewsYou} alt="" />
      </div>
      <div className="project1-content">
        <h3>I CHEWS YOU - recipe search engine</h3>
        <p>This was a group project at General Assembly. The task required a functional full-stack database backed application created in a team. We created the site utilising a recipe finder that connected to an API allowing users to search for recipes, like their favourite recipes and would eventually allow them to add and save them to their account.
        </p>
        <div className="project-links">
          <a href="https://github.com/0xJessez/I-Chews-You---Food-App">
            <FaGithubSquare />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Projects