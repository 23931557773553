function Skills() {

  function showSkills() {
    if (document.querySelector('.tech-skills-explained').hidden === true) {
      document.querySelector('.tech-skills-explained').hidden = false
    } else if (document.querySelector('.tech-skills-explained').hidden === false) {
      document.querySelector('.tech-skills-explained').hidden = true
    }
  }
  return (
    <div className="skills-page" onClick={showSkills}>
      <div className="skills-all">
        <h4>SKILLS</h4>
        <div className="tech-skills">
          <i title="Html" class="devicon-html5-plain"></i>
          <i class="devicon-javascript-plain"></i>
          <i class="devicon-css3-plain"></i>
          <i class="devicon-nodejs-plain"></i>
          <i class="devicon-postgresql-plain"></i>
          <i class="devicon-rails-plain"></i>
          <i class="devicon-ruby-plain"></i>
          <i class="devicon-sass-original"></i>
          <i class="devicon-react-original"></i>
          <i class="devicon-illustrator-plain"></i>
          <i class="devicon-photoshop-plain"></i>
          <i class="devicon-canva-original"></i>
          <i class="devicon-confluence-original"></i>
          <i class="devicon-figma-plain"></i>
          <i class="devicon-jira-plain"></i>
        </div>
        <div className="tech-skills-explained" hidden={true}>
          <p>
            HTML, Javascript, CSS, Node.js, Postgresql, Ruby on Rails, Ruby, Sass, React, Illustrator, Photoshop, Canva, Confluence, Figma, Jira.
          </p>
        </div>
      </div>
    </div>
  )
}


export default Skills