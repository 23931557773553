// import { FaDownload } from 'react-icons/fa'

function Resume() {

  return (
    <div className="resume-page">
      <div className="resume-content-all">
        <h4>RESUME</h4>
        <p>I'm a creative problem solver with a lot of experience in different industries including eCommerce, Graphic Design, Software Engineering, Customer Service and Hospitality</p>
        <p>I'm always interested in a new challenge or learning something new so please get in touch if you want to have a chat.</p>

        <div className='resume-links'>
          <a href={process.env.PUBLIC_URL + '/CaityLaneResume.pdf'} download="CaityLaneResume.pdf">
            Download
          </a>
        </div>
      </div>
    </div>
  )
}

export default Resume