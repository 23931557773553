// react
import React from 'react';

// icons
import { FaLinkedin, FaEnvelope, FaGithubSquare } from 'react-icons/fa'

// clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';


class Contact extends React.Component {
  state = {
    value: '',
    copied: false,
  }

  render() {
    return (
      <div className='contact-type'>
        <div className="contact-page">
          <h4>CONTACT ME</h4>
          <div className='contact-icons'>
            <a href='https://github.com/clane62'><FaGithubSquare /></a>

            <CopyToClipboard text="lane.caitlyn@gmail.com"
              onCopy={() => this.setState({ copied: true })} >
              < FaEnvelope />
            </CopyToClipboard>
            {this.state.copied ? <div style={{ color: 'black' }}> lane.caitlyn@gmail.com copied to clipboard.</div> : null}

            <a href='https://github.com/clane62'><FaLinkedin /></a>
          </div>
        </div>
        <p>Made in React by Caity Lane</p>
      </div>
    )
  }
}

export default Contact