import React, { useState, useEffect } from 'react';
import { FaAngleDoubleUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowScrollTopButton(true)
      } else {
        setShowScrollTopButton(false)
      }
    })
  }, [])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behaviour: 'smooth',
    })
  }
  return (
    <div className='top-button-visible'
      hidden={false}> {showScrollTopButton && < FaAngleDoubleUp className="top-btn-position" onClick={scrollTop} />}
    </div>
  )
}

export default ScrollToTop;